<template>
  <div>
    <v-card
      class="secondary card-radius"
      max-width="985"
      flat
    >
      <v-card-title>
        <v-img
          :src="require('../../assets/diet-pro-img.svg')"
          max-width="80"
          class="mx-auto mt-n12"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ?
            '12' : '7'">
            <v-row>
              <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown"></v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ?
              '12' : '9'">
                <p class="mx-2">{{ $t('health.allergies') }}</p>
                <v-text-field
                  :placeholder="$t('health.searchAllergies')"
                  class="white custom-field black--text mx-2"
                  outlined
                  rounded
                  dense
                  v-model="search"
                  hide-details=""
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown"></v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ?
              '12' : '9'">
                <v-card
                  class="white mx-2 scroll card-radius card-custom-horizantal-scroll"
                  flat
                  height="150"
                >
                  <v-row
                  >
                  <v-checkbox
                    v-for="(option, index) in options"
                    :key="index"
                    @change="chooseOptions(option)"
                    v-model="selectedOptions[index]"
                    class="mx-12 mt-1"
                    :label="$i18n.locale === 'en' ? option.nameEn : option.nameAr"
                    :value="option"
                    color="grey"
                    dense
                    hide-details
                  ></v-checkbox>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 
            '12' : '5'">
            <v-row>
              <template v-for="(i, index) in checkboxes">
                <v-col
                  v-if="i"
                  :key="index"
                  cols="6"
                  class="px-0 text-start"
                >
                  <v-chip
                    close
                    @click:close="removeOption(index, i)"
                    color="white black--text"
                  >
                    {{ $i18n.locale === 'en' ?
                      i.nameEn : i.nameAr }}
                  </v-chip>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-layout justify-center>
        <v-btn
          depressed
          rounded
          color="primary custom-font"
          class="black--text font-weight-light mb-12"
          :width="$vuetify.breakpoint.xs ? 250 : 315"
          type="submit"
          @click="getSelectedAllergies"
          >{{ $t('health.done') }}</v-btn
        >
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btn_loading: false,
      checkbox: [],
      checkboxes: [],
      options: [],
      selectedOptions: [],
      search: ''
    }
  },
  watch: {
    search(val) {
      this.$apollo.queries.getAllergies.refetch({
        input: {
          limit: 0,
          skip: 0
        },
        searchKey: val,
      });
    }
  },
  apollo: {
    getAllergies () {
      return {
        query: require("@/health/api/queries/allergies_query.graphql"),
        variables: {
          input: {
            limit: 0,
            skip: 0
          },
          searchKey: this.search
        },
        result({ data }) {
          // , loading, networkStatus, refetch
          this.options = data.getAllAllergyIngredients.ingredients
        }
      }
    }
  },
  methods: {
    chooseOptions (option) {
      this.checkboxes.push(option)
      this.checkboxes.forEach((x, index) => {
        if (!this.selectedOptions.includes(x))
          this.checkboxes.splice(index, 1)
      })
    },
    removeOption (index, i) {
      this.checkboxes.splice(index, 1)
      const selectedIndex = this.selectedOptions.findIndex( (element) => {
        if (element) return element._id === i._id
      })
      this.selectedOptions.splice(selectedIndex, 1, null)
    },
    getSelectedAllergies () {
      this.$root.$emit('selectedAllergies', this.checkboxes)
    }
  }
}
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
}
</style>